<!-- Card Name-->
 <form [formGroup]="form">
     <mat-form-field appearance="outline" class="hide-error">
         <mat-label>{{ "HALA.SendCardsEmployee.CardName" | translate }}</mat-label>
         <mat-select disableOptionCentering="true" formControlName="class_no">
             <!-- <mat-option value="" selected> {{'Common.Choose'|translate}} </mat-option> -->
             <mat-option class="theme-select" *ngFor="let item of dataSource" [value]="item.class_no" (click)="onClick()">
                 [{{ item.class_no }}] - {{ item.class_name }}
             </mat-option>
         </mat-select>
         <mat-error *ngIf="form.get('class_no')?.errors?.['required']">
             {{ 'Common.RequiredField' | translate }}
         </mat-error>
     </mat-form-field>
 </form>