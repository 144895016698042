import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { LanguageService } from '../../services/language.service';
import { ThPurchaseTargetService } from '../../services/thuraya/th-purchase-target.service';
import { Subject, takeUntil, tap } from 'rxjs';

@Component({
  selector: 'app-services-multiselect',
  templateUrl: './services-multiselect.component.html',
  styleUrls: ['./services-multiselect.component.scss']
})
export class ServicesMultiselectComponent implements OnInit {

  _unsubscribe$: Subject<boolean> = new Subject()
  //customer multi select variables
  servicesDropDownList:any[] = []
  servicesDropDownListFiltered:any[] = []

  @Input() selectedValues: any = []
  @Output() addToList: EventEmitter<any> = new EventEmitter();

  constructor(
    private thPurchaseTargetService: ThPurchaseTargetService,
    public languageService:LanguageService,
  ) { }

  
  // Called from parent on reset
  resetSelected(){
    this.selectedValues = []
  }

  ngOnInit(): void {
    // services multiselect settings
    this.getServicesData()
  }

  getServicesData() {
    this.thPurchaseTargetService.GetServicesData()
    .pipe(
      tap(
        (result) => {
          result.Data.map((i: any) => {
            this.servicesDropDownList.push({
              _id: i.service_id,
              name: i.fullName,
              photo: ''
            })
          });
          this.servicesDropDownListFiltered = [...this.servicesDropDownList]
        }
      ),
      takeUntil(this._unsubscribe$),
    )
    .subscribe();
  }

  onFilterChange(e:any){
    if(!e)
       this.servicesDropDownListFiltered=this.servicesDropDownList;
    else
    {
       e=e.toLowerCase();
       this.servicesDropDownListFiltered=this.servicesDropDownListFiltered.filter((x:any)=>x.fullName.toLowerCase()
          .indexOf(e)==0)

          //If no result show all else you get no data and no search bar
          if(this.servicesDropDownListFiltered.length==0){
            this.servicesDropDownListFiltered = this.servicesDropDownList
          }
    }
    
  }


  /*----------Adding tags to form arrays----------*/
  addToFiled(event: any) {
    this.selectedValues = event
    this.addToList.emit(this.selectedValues)
  }

  ngOnDestroy(): void {
    this._unsubscribe$.next(true)
    this._unsubscribe$.complete()
  }

}
