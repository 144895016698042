import { DatePipe, Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { UntypedFormArray, UntypedFormGroup } from '@angular/forms';
import { MatButton } from '@angular/material/button';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject, fromEvent, take, takeUntil, tap } from 'rxjs';
import { MatDialogRef } from '@angular/material/dialog';

export enum KeyboardEscMode {
  rest = 'Rest',
  back = 'Back',
  closeDialog = 'Close Dialog',
}

@Injectable({
  providedIn: 'root',
})
export class HelperService {
  constructor(
    private location: Location,
    // private SideBarService: SideBarService,
    // private BreakPointStyleService: BreakPointStyleService,
    private router: Router,
    private route: ActivatedRoute,
    private DatePipe: DatePipe,
  ) { }

  /**
   *remove any empty key
   *filter any object and return _id directly
   */
  handleControl(obj: any) {
    let newObj = { ...obj }
    for (const [key, value] of Object.entries(newObj)) {
      /**
       * (!value) Delete Empty Key
       * (value != 'boolean') To keep any Key With Flase Boolean
       */
      if (!value && typeof value != 'boolean') {
        delete newObj[key];
      }
      //Return ID Form Object
      if ((value as any)?._id) {
        newObj[key] = (value as any)._id;
      }
      //If There Is Key As Array
      if (Array.isArray(value)) {
        let ids = value.map((x) => {
          return x._id ? x._id : x;
        });
        newObj[key] = [...ids];
      }
    }

    return newObj;
  }

  /**
   *
   generate random code and return it
   */
  generateRandomCode(minLength: any = 6) {
    let result = '';
    let characters =
      'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let charactersLength = characters.length;
    for (let i = 0; i < minLength; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }
  /*


  /**
   * go back using browser location back
   */
  previousPage() {
    this.location.back();
  }

  trackBy(i: number, item: any) {
    return item._id;
  }

  /**
   * usage: clear form array one by one
   * @param formArray target form array
   */
  clearFormArray(formArray: UntypedFormArray) {
    while (formArray.value.length !== 0) {
      formArray.removeAt(0);
    }
  }

  /**
   *
   * @param event mouse event
   * @param btn target btn to click
   */
  keyboardEnterToSubmit(
    event: KeyboardEvent,
    btn: MatButton,
    escBtn?: MatButton,
    dialogRef?: MatDialogRef<any>
  ) {
    event.stopPropagation();

    if (event.key == 'Enter') {
      btn._elementRef.nativeElement.click();
    }
    if (event.key == 'Escape') {
      if (dialogRef) {
        dialogRef.close();
      } else {
        escBtn?._elementRef.nativeElement.click();
      }
    }
  }
  /**
   * component : { $this : any , formName : string}
   * @param mode
   */
  keyboardESCButtonHandler<T>(
    mode: KeyboardEscMode,
    dialogRef?: MatDialogRef<T, any>,
    form?: UntypedFormGroup
  ) {
    return fromEvent(document, 'keyup').pipe(
      take(1),
      tap((letter: any) => {
        if (letter.code == 'Escape' && mode == KeyboardEscMode.back) {
          this.location.back();
        } else if (
          letter.code == 'Escape' &&
          mode == KeyboardEscMode.rest &&
          form
        ) {
          form.reset();
        } else if (
          letter.code == 'Escape' &&
          mode == KeyboardEscMode.closeDialog &&
          dialogRef
        ) {
          dialogRef.close();
        }
      })
    );
  }
  /**
   * use: helper.autoCompleteDisplayFn.bind(objectList : [], targetToDisplay : [] , this : string)
   * @param objectList
   * @param targetToDisplay
   * @param value
   * @returns
   */
  autoCompleteDisplayFn(
    objectList: any[],
    targetToDisplay: string[],
    value: any
  ): string {
    if (typeof value === 'string') {
      let object = objectList.find((x: any) => x._id == value);
      return object
        ? targetToDisplay
          .map((x) => x.split('.')?.reduce((a, b) => a?.[b], object) ?? '')
          .join(' ')
        : '';
    }

    return value
      ? targetToDisplay
        ?.map((x) => x.split('.')?.reduce((a, b) => a?.[b], value) ?? '')
        .join(' ') ?? ''
      : '';
  }

  /**
   * usage : when you want to add and update objects in array
   * with other array of same object
   *
   * @param targetList main array
   * @param pushedList sub array
   * @returns compined array
   */
  pushAndUpdateArray(targetList: any[], pushedList: any[]): any[] {
    if (pushedList.length > 0) {
      if (targetList.length == 0) return pushedList;
      if (targetList.length > 0) {
        targetList = [
          ...targetList.map((x) => pushedList.find((c) => c._id == x._id) || x),
          ...pushedList.filter(
            (x) => targetList.findIndex((c) => c._id == x._id) == -1
          ),
        ];
        return targetList;
      }
    }
    return [];
  }

  /**
   * close side bar on click
   */
  closeSideBar() {
    // if (this.BreakPointStyleService.cols$.getValue() > 2) return
    // this.SideBarService.isClosed$.next(true)
  }

  /**
   * reload componant without reload page
   * @param self <-- true same route
   * @param urlToNavigateTo <-- self = false && other route
   */
  reloadComponent() {
    //skipLocationChange:true means dont update the url to / when navigating
    let _subscribe: Subject<boolean> = new Subject();
    const url = this.router.url;
    let queryParam: any;
    this.route.queryParamMap
      .pipe(takeUntil(_subscribe))
      .subscribe((query: any) => {
        if (Object.keys(query['params']).length > 0)
          queryParam = query['params'];
        this.router
          .navigateByUrl('/', { skipLocationChange: true })
          .then(() => {
            this.router
              .navigate([`${url.split('?')[0]}`], { queryParams: queryParam })
              .then(() => {
                _subscribe.next(true);
                _subscribe.complete();
              });
          });
      });
  }

  /**
   * get defult day from month and year
   * @param date
   */
  getDefultDateUTCFromMonthAndYear(date: string) {
    return new Date(
      Date.UTC(new Date(date).getFullYear(), new Date(date).getMonth(), 1)
    ).toISOString();
  }

  /**
   * get defult day from month and year
   * @param date
   * @param day
   * @param addMonth
   */
  getDefultDateUTCFromDateWithDayAndAddMonth(
    date: string,
    day: number,
    addMonth: number
  ) {
    return new Date(
      Date.UTC(
        new Date(date).getFullYear(),
        new Date(date).getMonth() + addMonth,
        day
      )
    ).toISOString();
  }

  /**
   * assign by value
   * @param OBJ
   * @returns
   */
  getValueOfOBJ(OBJ: any) {
    return JSON.parse(JSON.stringify(OBJ));
  }

  getDaysStringBasedOnNumber(days: number) {
    let daysString = 'common.';
    if (days == 1) daysString += 'day';
    if (days == 2) daysString += 'day2';
    if (days > 2 && days < 11) daysString += 'days';
    if (days > 11) daysString += 'day3';
    if (days == 0) daysString = '';
    return daysString;
  }

  checkStartDateNotBeforeEndDate(startDate: Date, endDate: Date) {
    let startTimeStamp = startDate.getTime();
    let endTimeStamp = endDate.getTime();
    return endTimeStamp > startTimeStamp;
  }

  /**
   * get iso format without -+ zone defrance
   * @param date
   * @returns string iso formate
   */
  DateToISOStringWithoutZone(date: any) {
    if (date == '') {
      return '';
    }
    return this.DatePipe.transform(date, 'yyyy-MM-ddTHH:mm:ss.000') + 'Z';
  }
  //Prevent negative numbers
  removeNegativeValues(input: number) {
    if (input.toString().includes('-')) {
      return '';
    }
    return input;
  }

  //Get name initails
  formatLetter(name: string | undefined) {
    return name?.slice(0, 1);
  }

  get12hrFormat(value:any){
    var [h, m] = value.split(":");
    let time = h >= 12 ? 'PM' : 'AM'
    return (h % 12 ? h % 12 : 12) + ":" + m + ' '+ time
  }

  getDifferencebetweenTimes(time1: any, time2: any) {
    const [hours1, minutes1] = time1.split(':').map(Number);
    const [hours2, minutes2] = time2.split(':').map(Number);

    const totalMinutes1 = hours1 * 60 + minutes1;
    const totalMinutes2 = hours2 * 60 + minutes2;

    let diffMinutes = totalMinutes1 - totalMinutes2;

    const resultHours = Math.floor(diffMinutes / 60);
    const resultMinutes = diffMinutes % 60;
    let totalHours = resultHours + resultMinutes / 60;
    return totalHours;
  }
}
