import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-classes-dropdown',
  templateUrl: './classes-dropdown.component.html',
  styleUrl: './classes-dropdown.component.scss'
})
export class ClassesDropdownComponent {

  @Input() dataSource:any[] = []
  @Output() setSelectedCard: EventEmitter<any> = new EventEmitter();
  @Output() setSelectedCardName: EventEmitter<any> = new EventEmitter();

  form: FormGroup= this.fb.group({
    class_no: new FormControl(""),
  });;

  reset(){
    this.form.reset()
  }
  constructor(
    private fb: FormBuilder,
  ) { }

  onClick(){
    let selected = this.form.get('class_no')?.value
    let item = this.dataSource.find(i=>i.class_no==selected)
    this.setSelectedCard.emit(selected)
    this.setSelectedCardName.emit(item.class_name)
  }


}
