import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { IResponse } from '../../models/iresponse';
import { Groups } from '../../models/groups';
import { CompaniesGroupingData } from '../../models/companiesGroupingData';
import { CompaniesDataNotInGrouping } from '../../models/companiesDataNotInGrouping';

@Injectable({
  providedIn: 'root'
})
export class HalaCompaniesSettingsService {

  API_URLGetAllGroups = `${environment.apiUrl}/HalaCompaniesGrouping/GetAllGroups`;
  API_URLAddUpdateNewGroup = `${environment.apiUrl}/HalaCompaniesGrouping/AddUpdateNewGroup`;
  API_URLDeleteGroup = `${environment.apiUrl}/HalaCompaniesGrouping/DeleteGroup`;
  API_URLGetAllCompaniesGroupingData = `${environment.apiUrl}/HalaCompaniesGrouping/GetAllCompaniesGroupingData`;
  API_URLGetAllCompaniesDataNotInGrouping = `${environment.apiUrl}/HalaCompaniesGrouping/GetAllCompaniesDataNotInGrouping`;
  API_URLAddNewCompaniesGrouping = `${environment.apiUrl}/HalaCompaniesGrouping/AddNewCompaniesGrouping`;
  API_URLDeleteCompaniesGrouping = `${environment.apiUrl}/HalaCompaniesGrouping/DeleteCompaniesGrouping`;
  
  constructor(private http:HttpClient) { }

  GetAllGroups(): Observable<IResponse<Groups>> {
    const url = this.API_URLGetAllGroups;
    //Http request
    return this.http.get<IResponse<Groups>>(url)
  }

  AddUpdateNewGroup(body:any): Observable<any> {
    const url = this.API_URLAddUpdateNewGroup;
    //Http request
    return this.http.post<any>(url,body)
  }

  DeleteGroup(body:any): Observable<any> {
    const url = this.API_URLDeleteGroup;
    //Http request
    return this.http.post<any>(url,body)
  }

  GetAllCompaniesGroupingData(body:any): Observable<IResponse<CompaniesGroupingData>> {
    const url = this.API_URLGetAllCompaniesGroupingData;
    //Http request
    return this.http.post<IResponse<CompaniesGroupingData>>(url,body)
  }

  GetAllCompaniesDataNotInGrouping(body:any): Observable<IResponse<CompaniesDataNotInGrouping>> {
    const url = this.API_URLGetAllCompaniesDataNotInGrouping;
    //Http request
    return this.http.post<IResponse<CompaniesDataNotInGrouping>>(url,body)
  }

  AddNewCompaniesGrouping(body:any): Observable<IResponse<CompaniesGroupingData>> {
    const url = this.API_URLAddNewCompaniesGrouping;
    //Http request
    return this.http.post<IResponse<CompaniesGroupingData>>(url,body)
  }

  DeleteCompaniesGrouping(id:any): Observable<any> {
    const url = this.API_URLDeleteCompaniesGrouping;
    //Http request
    return this.http.get<any>(`${url}?rowId=${id}`)
  }

}
