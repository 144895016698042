import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Validators, FormBuilder } from '@angular/forms';
import { Subject, tap, takeUntil } from 'rxjs';
import { HelperService } from '../../services/Helper.service';
import { autoCompleteRequired } from '../../validators/autocompleteRequired';
import { HalaBankService } from '../../services/hala/hala-bank.service';

@Component({
  selector: 'app-banks-dropdown',
  templateUrl: './banks-dropdown.component.html',
  styleUrl: './banks-dropdown.component.scss'
})
export class BanksDropdownComponent {

  _unsubscribe$: Subject<boolean> = new Subject()

  form = this.fb.group({
    Id: ['', [Validators.required,autoCompleteRequired()]],
  })
  dataSource:any[] = []
  timeOut:any=0
  @Input() label:string = "Common.Bank"
  @Output() updateParent: EventEmitter<any> = new EventEmitter();

  constructor(
    private halaBankService:HalaBankService,
    private fb: FormBuilder,
    public helper:HelperService
  ) { }

  ngOnInit(): void {
    this.getBanks()
  }

  /*----------------------Functions Called from parent---------------------- */
  setValue(data:any){
    this.form.get('Id')?.setValue(data)
  }
  reset(){
    this.form.reset()
  }



  onType(value: string) {
    if (value.length == 0 || value.length > 1) {
      clearTimeout(this.timeOut)
      this.timeOut = setTimeout(() => {
        this.dataSource=[]
        this.getBanks(value)
      }, 1000)
    }
  }

  getBanks(searchValue:string='') {
    let body = {
      pageNumber:1,
      pageSize: 100,
      isPaginated: false,
      searchStrings:searchValue
    }

    this.halaBankService.GetAllBank(body)
    .pipe(
      tap(
        (result) => {
          if(result.IsSuccess && result.Data){
            this.dataSource = result.Data;
          }
        }
      ),
      takeUntil(this._unsubscribe$),
    )
    .subscribe();
  }

  /*----------------------when Choosing---------------------- */
  changeSelected(selectedValue:any){ 
    // console.log("selected",selectedValue)
    if(selectedValue){
      let id= (this.form.get('Id')?.value as any).Id
      this.updateParent.emit(id);   
    }else{
      this.updateParent.emit("");
    }
  }

  displayFn(item:any) {
    if (!item) return '';
    let index = this.dataSource.findIndex(e => e.Id == item.Id);
    return `[${this.dataSource[index].Id}] - ${this.dataSource[index].BankName}`;
  }

  ngOnDestroy(): void {
    this._unsubscribe$.next(true)
    this._unsubscribe$.complete()
  }
}
