import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-primary-button',
  templateUrl: './primary-button.component.html',
  styleUrls: ['./primary-button.component.scss'],
})
export class PrimaryButtonComponent {
  @Input() label: string = '';
  @Input() lucidePrefixIcon:string = ''
  @Input() lucidePrefixSize:string = '16'
  @Input() lucidePrefixColor:string = '#FFFFFF'
  @Input() prefixIcon: string = '';
  @Input() isDisabled: boolean = false;
  @Input() isLoading: boolean = false;
  @Input() hasDropdown: boolean = false;

  @Output() handleClick = new EventEmitter<any>();

  click() {
    this.handleClick.emit();
  }

  ngOnInit() {
    // console.log('🚀🏎️', this.prefixIcon);
  }
}
