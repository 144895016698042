import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ThGeneralSystemService } from '../../services/thuraya/th-general-system.service';
import { Subject, takeUntil, tap } from 'rxjs';
import { FormBuilder, Validators } from '@angular/forms';
import { autoCompleteRequired } from '../../validators/autocompleteRequired';
import { HelperService } from '../../services/Helper.service';

@Component({
  selector: 'app-all-customers-dropdown',
  templateUrl: './all-customers-dropdown.component.html',
  styleUrls: ['./all-customers-dropdown.component.scss']
})
export class AllCustomersDropdownComponent implements OnInit {


  _unsubscribe$: Subject<boolean> = new Subject()

  form = this.fb.group({
    customer_id: ['', [Validators.required,autoCompleteRequired()]],
  })
  dataSource:any[] = []
  timeOut:any=0
  @Input() label:string = "Common.Clients"
  @Output() updateParent: EventEmitter<any> = new EventEmitter();

  constructor(
    private thGeneralSystemService:ThGeneralSystemService,
    private fb: FormBuilder,
    public helper:HelperService
  ) { }

  ngOnInit(): void {
    this.getCustomers()
  }

  /*----------------------Functions Called from parent---------------------- */
  setValue(data:any){
    this.form.get('customer_id')?.setValue(data)
  }
  reset(){
    this.form.reset()
  }



  onType(value: string) {
    if (value.length == 0 || value.length > 1) {
      clearTimeout(this.timeOut)
      this.timeOut = setTimeout(() => {
        this.dataSource=[]
        this.getCustomers(value)
      }, 1000)
    }
  }

  getCustomers(searchValue:string='') {
    let body = {
      pageNumber:1,
      pageSize: 100,
      isPaginated: false,
      searchStrings:searchValue
    }

    this.thGeneralSystemService.GetAllCustomerAccountData(body)
    .pipe(
      tap(
        (result) => {
          if(result.IsSuccess && result.Data){
            this.dataSource = result.Data;
          }
        }
      ),
      takeUntil(this._unsubscribe$),
    )
    .subscribe();
  }

  /*----------------------when Choosing service---------------------- */
  changeSelected(selectedValue:any){ 
    // console.log("selected",selectedValue)
    if(selectedValue){
      let id= (this.form.get('customer_id')?.value as any).customer_id
      this.updateParent.emit(id);   
    }else{
      this.updateParent.emit("");
    }
  }

  displayFn(item:any) {
    if (!item) return '';
    let index = this.dataSource.findIndex(e => e.customer_id == item.customer_id);
    return `[${this.dataSource[index].customer_id}] - ${this.dataSource[index].customer_name}`;
  }

  ngOnDestroy(): void {
    this._unsubscribe$.next(true)
    this._unsubscribe$.complete()
  }

}
