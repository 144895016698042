<div class="dropdown nav-item" ngbDropdown>
    <button type="button" class="btn header-item" id="page-header-user-dropdown" ngbDropdownToggle>
        <img *ngIf="flagvalue !== undefined" src="{{flagvalue}}" alt="Header Language" height="16"> 
        <span [ngClass]=" isLoginView ? 'text-dark' : 'text-white'"  style="margin-inline-start: 5px;">{{countryName | translate}}</span>
    </button>
    <div class="dropdown-menu dropdown-menu-end" ngbDropdownMenu>
    <!-- drop items-->
    <a href="javascript:void(0);" class="dropdown-item notify-item" *ngFor="let item of listLang"
        (click)="setLanguage(item.text, item.lang, item.flag)" [ngClass]="{'active': cookieValue === item.lang}">
        <img src="{{item.flag}}" alt="user-image" height="12"> 
        <span class="align-middle" style="margin-inline-start: 15px;">{{item.text | translate}}</span>
    </a>
    </div>
</div>