import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ThGeneralSystemService } from '../../services/thuraya/th-general-system.service';
import { Subject, takeUntil, tap } from 'rxjs';
import { FormBuilder, Validators } from '@angular/forms';
import { autoCompleteRequired } from '../../validators/autocompleteRequired';
import { HelperService } from '../../services/Helper.service';

@Component({
  selector: 'app-services-dropdown',
  templateUrl: './services-dropdown.component.html',
  styleUrls: ['./services-dropdown.component.scss']
})
export class ServicesDropdownComponent implements OnInit {
  
  _unsubscribe$: Subject<boolean> = new Subject()

  form = this.fb.group({
    service_id: ['', [Validators.required,autoCompleteRequired()]],
  })
  dataSource:any[] = []
  timeOut:any=0
  @Output() updateParent: EventEmitter<any> = new EventEmitter();

  constructor(
    private thGeneralSystemService:ThGeneralSystemService,
    private fb: FormBuilder,
    public helper:HelperService
  ) { }

  ngOnInit(): void {
    this.getServices()
  }

  /*----------------------Functions Called from parent---------------------- */
  setValue(data:any){
    this.form.get('service_id')?.setValue(data)
    // console.log("eventdata",data)
    // this.serviceNameVal = `[${data.Service_Id}] - ${data.Service_Id}`;
  }
  reset(){
    this.form.reset()
    // console.log("called")
    // this.serviceNameVal = ""
  }



  onTypeService(value: string) {
    if (value.length == 0 || value.length > 1) {
      clearTimeout(this.timeOut)
      this.timeOut = setTimeout(() => {
        this.dataSource=[]
        this.getServices(value)
      }, 1000)
    }
  }

  getServices(searchValue:string='') {
    let body = {
      pageNumber:1,
      pageSize: 100,
      isPaginated: false,
      searchStrings:searchValue
    }

    this.thGeneralSystemService.GetServicesData(body)
    .pipe(
      tap(
        (result) => {
          if(result.IsSuccess && result.Data){
            this.dataSource = result.Data;
          }
        }
      ),
      takeUntil(this._unsubscribe$),
    )
    .subscribe();
  }

  /*----------------------when Choosing service---------------------- */
  changeSelectedService(selectedValue:any){ 
    // console.log("selected",selectedValue)
    if(selectedValue){
      let id= (this.form.get('service_id')?.value as any).service_id
      this.updateParent.emit(id);   
    }else{
      this.updateParent.emit("");
    }
  }

  displayFn(item:any) {
    if (!item) return '';
    let index = this.dataSource.findIndex(e => e.service_id == item.service_id);
    return `[${this.dataSource[index].service_id}] - ${this.dataSource[index].fullName}`;
  }

  ngOnDestroy(): void {
    this._unsubscribe$.next(true)
    this._unsubscribe$.complete()
  }
}
