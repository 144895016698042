<div class="custom-tables table-responsive mb-0">
    <table class="table table-centered table-nowrap">
      <thead class="">
        <tr>
          <th class="align-middle">{{'Common.ItemCode'| translate}}</th>
          <th class="align-middle">{{'Common.ItemName'| translate}}</th>
          <th class="align-middle">{{'Common.Amount'| translate}}</th>
          <th class="align-middle">{{'Common.UnitPrice'| translate}}</th>
          <th class="align-middle">{{'Common.Total'| translate}} </th>
          <th class="align-middle">{{'Common.Tax'| translate}}% </th>
          <th class="align-middle">{{'Common.Tax'| translate}} </th>
          <th class="align-middle"> {{'Common.Value'| translate}}</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let data of datasource">
          <td> <a class="text-body fw-bold">{{data.I_CODE}}</a> </td>
          <td> {{data.Note}} </td>
          <td>{{data.I_QTY}}</td>
          <td>{{data.I_PRICE}}</td>
          <td>{{data.totalBeforeVAT}}</td>
          <td>0.00</td>
          <td>0.00</td>
          <td>{{data.totalBeforeVAT}}</td>
        </tr>
        <tr>
          <td><span style="font-weight: 800;">{{'Common.Total'| translate}}</span> </td>
          <td></td>
          <td>
            <span>{{sumQty==0 ? '-' :sumQty}}</span>
          </td>
          <td></td>
          <td>
            <span>{{sumTotal==0 ? '-' :sumTotal | number : '1.0-2'}}</span>
          </td>
          <td></td>
          <td></td>
          <td>
            <span>{{sumTotal==0 ? '-' :sumTotal | number : '1.0-2'}}</span>
          </td>
        </tr>
      </tbody>
    </table>
</div>