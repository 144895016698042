import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ThGeneralSystemService } from '../../services/thuraya/th-general-system.service';
import { Subject, takeUntil, tap } from 'rxjs';
import { FormBuilder, Validators } from '@angular/forms';
import { autoCompleteRequired } from '../../validators/autocompleteRequired';
import { Companies } from '../../models/companies';
import { IResponse } from '../../models/iresponse';
import { HalaCompaniesSettingsService } from '../../services/hala/hala-companies-settings.service';

@Component({
  selector: 'app-companies-dropdown',
  templateUrl: './companies-dropdown.component.html',
  styleUrls: ['./companies-dropdown.component.scss']
})
export class CompaniesDropdownComponent implements OnInit {

  dataSource:Companies[] = []
  timeOut:any=0
  _unsubscribe$: Subject<boolean> = new Subject()

  form = this.fb.group({
    company_id: ['', [Validators.required,autoCompleteRequired()]],
  }) 
  @Output() updateParent: EventEmitter<any> = new EventEmitter();
  @Input() notInGroup:boolean = false
  @Input() label:string = 'Common.Companies'

  constructor(
    private fb: FormBuilder,
    private thGeneralSystemService:ThGeneralSystemService,
    private halaCompaniesSettingsService:HalaCompaniesSettingsService,
  ) { }

  ngOnInit(): void {
    this.getAllCompanies()
  }

  /*----------------------Functions Called from parent---------------------- */
  emptyData(data:any){
    this.dataSource = []
    this.getAllCompanies();
  }

  reset(){
    this.form.reset()
  }
  

  /*----------------------Getting Companies for dropdown select---------------------- */

  onType(value: string) {
    if (value.length == 0 || value.length > 1) {
      clearTimeout(this.timeOut)
      this.timeOut = setTimeout(() => {
        this.dataSource=[]
        this.getAllCompanies(value)
      }, 1000)
    }
  }

  getAllCompanies(searchValue:string="") {
    let body ={
      pageNumber: 1,
      pageSize: 100,
      searchStrings: searchValue,
      isPaginated: true,
    }
  
    let api:any = this.notInGroup ? this.halaCompaniesSettingsService.GetAllCompaniesDataNotInGrouping(body) :  this.thGeneralSystemService.GetAllCompanies(body)
    api
    .pipe(
      tap(
        (result:IResponse<Companies>) => {
          if(result.IsSuccess && result.Data){
            this.dataSource = result.Data;
          }
        }
      ),
      takeUntil(this._unsubscribe$),
    )
    .subscribe();
  }

  /*----------------------when Choosing Company---------------------- */
  changeSelectedCompany(selectedValue:any){ 
    // console.log("selected",selectedValue)
    if(selectedValue){
      let id = (this.form.get('company_id')?.value as any).company_no
      this.updateParent.emit(id);   
    }else{
      this.updateParent.emit("");
    }
  }

  displayFn(item:any) {
    if (!item) return '';
    let index = this.dataSource.findIndex(e => e.company_no == item.company_no);
    return `[${this.dataSource[index].company_no}] - ${this.dataSource[index].company_name}`;
  }


  ngOnDestroy(): void {
    this._unsubscribe$.next(true)
    this._unsubscribe$.complete()
  }

}
