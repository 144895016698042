<div class="custom-checkbox" [ngClass]="showfocusShadow ? 'show-focus' : ''">
  <input
    type="checkbox"
    [id]="id"
    [value]="value"
    (change)="click($event)"
    [checked]="isChecked"
    [indeterminate]="isIndeterminate"
    [disabled]="isDisabled"
  />
  <label class="label-regular" [for]="id">
    {{ label ? (label | translate) : "" }}</label
  >
</div>
