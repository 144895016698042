<!--begin::Stats Widget 29-->
<div class="info-card card card-custom bgi-no-repeat card-stretch gutter-b">
<!--begin::Body-->
<div class="card-body">
    
    <!-- <i class="info-icon d-block" [className]="'fa fa-' + icon" [ngStyle]="{'color': iconColor}"  style="margin-bottom: 10px !important;"></i> -->
    <div *ngFor="let header of headerText let i = index">
        <div class="d-flex gap-2">
            @if (icon) {
                <lucide-icon class="mb-1" [color]="iconColor" [name]="icon" [size]="iconSize"></lucide-icon>
            }
            <h2 class="heading-text">{{header}}:</h2>
        </div>
        <!-- <h5 class="font-weight-bold d-inline-block mx-1">{{header}}:</h5> -->
        <span class="card-title font-weight-bolder font-size-h1 mb-0 " [ngStyle]="{'color': iconColor}">{{ amount[i] }}</span>
        <span class="font-weight-bold text-muted font-size-sm mx-1">{{endText[i]}}</span>
    </div>
</div>
<!--end::Body-->
</div>
<!--end::Stats Widget 29-->
