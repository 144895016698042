import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-custom-toggle',
  templateUrl: './custom-toggle.component.html',
  styleUrls: ['./custom-toggle.component.scss'],
})
export class CustomToggleComponent {
  @Output() handleClick = new EventEmitter<any>();
  @Input() id: string = '';
  @Input() isChecked?: boolean = true;
  @Input() value?: any = '';
  @Input() isDisabled?: boolean = false;
  @Input() label?: string = '';

  click(event: any) {
    this.handleClick.emit(event);
  }
}
