import { Component } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import { Router } from '@angular/router';
import { animate, state, style, transition, trigger } from '@angular/animations';

@Component({
  selector: 'app-profile-menu',
  templateUrl: './profile-menu.component.html',
  styleUrl: './profile-menu.component.scss',
  animations: [
    trigger('menuState', [
      state('closed', style({
        opacity: 0,
        transform: 'translateY(20%)',
        display: 'none'
      })),
      state('open', style({
        opacity: 1,
        transform: 'translateY(20%)',
        display: 'block'
      })),
      transition('closed => open', [
        style({ display: 'block' }),
        animate('300ms ease-in')
      ]),
      transition('open => closed', [
        animate('300ms ease-out', style({ opacity: 0, transform: 'translateY(20%)' })),
        style({ display: 'none' })
      ])
    ])
  ]
})
export class ProfileMenuComponent {


  isMenuOpen :boolean = false
  constructor(
		public authService:AuthService,
    private router:Router
	) { }

  goToProfile(){
    this.router.navigateByUrl('/profile')
  }

  toggleMenu(){
    this.isMenuOpen  = !this.isMenuOpen 
  }
}
