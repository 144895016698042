<mat-form-field appearance="outline" [floatLabel]="selectedTags.length>0 ? 'always' :'auto'" >
  <mat-label >{{filedName |translate}}</mat-label>
    <mat-chip-grid #chipGrid aria-label="Enter fruits">

      <ng-container *ngFor="let tag of selectedTags;let i=index">
          <app-custom-chip-colored 
            class="p-1"
            [label]="tag.name" 
            [color]="tag.color ?? tagBgColor" 
            [labelColor]="'#303030'" 
            [closeColor]="tag.closeColor ?? tagCloseColor" 
            [prefixAvatar]="tag.photo" 
            [prefixAvatarWidth]="'1rem'" 
            [prefixAvatarHeight]="'1rem'"
            [showClose]="showTagClose"
            (handleClick)="remove(i)"
          > </app-custom-chip-colored>
        </ng-container>

      <input
          type="text"
          aria-label="auto complete"
          matInput
          [disabled]="isDisabled"
          [matChipInputFor]="chipGrid"
          [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
          [matAutocomplete]="autoList"
          (blur)="onblurInput()"
          (input)="typeHandle(autocompleteList.value)"
          [disabled]="isDisabled"
          #autocompleteList
      />
      <mat-autocomplete
            #autoList="matAutocomplete"
            (optionSelected)="select($event.option.value)"
        >
            <mat-option *ngIf="autoCompleteList.length == 0"></mat-option>
            <mat-option *ngFor="let item of autoCompleteList" [value]="item">
            {{item.name }}
            </mat-option>
      </mat-autocomplete>
    </mat-chip-grid>
  </mat-form-field>