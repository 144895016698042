import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-date-selector',
  templateUrl: './date-selector.component.html',
  styleUrls: ['./date-selector.component.scss']
})
export class DateSelectorComponent implements OnInit {


  dateForm: FormGroup= this.fb.group({
    dateSelected: new FormControl(""),
  });;

  dateSelctedValue:any = ''
  dateSelctedTimeStamp:any = ''

  @Input() label:string=''
  @Input() minDate:Date|undefined
  @Output() setSelectedDate: EventEmitter<any> = new EventEmitter();

  constructor(
    private fb: FormBuilder,
  ) { }

  ngOnInit(): void {
    this.initForm()
    this.getDate();
    let body = {
      date: this.dateSelctedValue,
      dateTimeStamp: this.dateSelctedTimeStamp
    }
    this.setSelectedDate.emit(body)
  }
  /*----------------------Functions Called from parent---------------------- */
  reset(){
    this.initForm()
  }

  setDate(date:any){
    this.dateForm.get('dateSelected')?.setValue(date)
  }

  /*--------------------------Form Functions-------------------------- */
  initForm(){
    this.dateForm = this.fb.group({
      dateSelected: new FormControl(""),
    });
  }

  get f() {
    return this.dateForm.controls;
  }

  /*--------------------------On Date Select-------------------------- */
  onDateSelect(event:any){
    this.getDate()
    let body = {
      date: this.dateSelctedValue,
      dateTimeStamp: this.dateSelctedTimeStamp
    }
    this.setSelectedDate.emit(body)
  }

  /*--------------------------Clear Date-------------------------- */
  clearDate(){
    this.initForm()
    this.getDate();
    let body = {
      date: this.dateSelctedValue,
      dateTimeStamp: this.dateSelctedTimeStamp
    }
    this.setSelectedDate.emit(body)
  }

  
  /*--------------------------Format date for requests-------------------------- */
  pipe = new DatePipe('en-US');
  getDate() {
    let dateSelcted = this.f['dateSelected'].value;
    if (dateSelcted) {
      this.dateSelctedTimeStamp = dateSelcted.getTime()
      this.dateSelctedValue = this.pipe.transform(dateSelcted, 'MM/dd/yyyy');
    } else {
      this.dateSelctedValue = "";
      this.dateSelctedTimeStamp  = "";
    }
  }

}
