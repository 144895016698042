<!-- Card Name-->
<form [formGroup]="form">
    <mat-form-field appearance="outline" class="hide-error">
        <mat-label>{{ label | translate }}</mat-label>
        <mat-select disableOptionCentering="true" formControlName="CUSTOMER_ID">
            <mat-option value=""> {{'Common.Choose'|translate}} </mat-option>
            <mat-option class="theme-select" *ngFor="let item of dataSource" [value]="item" (click)="onClick()">
                [{{ item.CUSTOMER_ID }}] - {{ item.CUSTOMER_NAME }}
            </mat-option>
        </mat-select>
        <mat-error *ngIf="form.get('CUSTOMER_ID')?.errors?.['required']">
            {{ 'Common.RequiredField' | translate }}
        </mat-error>
    </mat-form-field>
</form>