import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { HalaAccountsService } from '../../services/hala/hala-accounts.service';
import { FormBuilder, Validators } from '@angular/forms';
import { autoCompleteRequired } from '../../validators/autocompleteRequired';
import { Subject, takeUntil, tap } from 'rxjs';

@Component({
  selector: 'app-users-dropdown',
  templateUrl: './users-dropdown.component.html',
  styleUrls: ['./users-dropdown.component.scss']
})
export class UsersDropdownComponent implements OnInit {

  _unsubscribe$: Subject<boolean> = new Subject()

  form = this.fb.group({
    user_id: ['', [Validators.required,autoCompleteRequired()]],
  })

  @Input() label:string = "Common.UserId"
  @Output() updateParent: EventEmitter<any> = new EventEmitter();

  dataSource:any[] = []
  timeOut:any=0

  constructor(
    private halaAccountsService: HalaAccountsService,
    private fb: FormBuilder,
  ) { }

  ngOnInit(): void {
    this.getUsers()
  }

  /*----------------------Functions Called from parent---------------------- */
  // setValue(data){
  //   // console.log("eventdata",data)
  //   this.userVal = `[${data.Service_Id}] - ${data.Service_Id}`;
  // }

  reset(){
    this.form.reset()
  }

  /*----------------------Getting Users for dropdown select---------------------- */

  onType(value: string) {
    if (value.length == 0 || value.length > 1) {
      clearTimeout(this.timeOut)
      this.timeOut = setTimeout(() => {
        this.dataSource=[]
        this.getUsers(value)
      }, 1000)
    }
  }

  getUsers(searchValue:string='') {
    let body = {
      pageNumber:1,
      pageSize: 100,
      isPaginated: false,
      searchStrings:searchValue
    }
     this.halaAccountsService.GetUsersDataActive(body)
     .pipe(
      tap(
        (result) => {
          if(result.IsSuccess && result.Data.users){
            this.dataSource = result.Data.users;
          }
        }
      ),
      takeUntil(this._unsubscribe$),
    )
    .subscribe();
  }


  /*----------------------when Choosing User---------------------- */
  changeSelected(selectedValue:any){ 
    // console.log("selected",selectedValue)
    if(selectedValue){
      let phone= (this.form.get('user_id')?.value as any).PhoneNumber
      this.updateParent.emit(phone);   
    }else{
      this.updateParent.emit("");   
    }
  }

  displayFn(item:any) {
    if (!item) return '';
    let index = this.dataSource.findIndex(e => e.Id == item.Id);
    return `[${this.dataSource[index].PhoneNumber}] - ${this.dataSource[index].FullName}`;
  }

  ngOnDestroy(): void {
    this._unsubscribe$.next(true)
    this._unsubscribe$.complete()
  }
}
