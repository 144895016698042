import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-custom-chip-colored',
  templateUrl: './custom-chip-colored.component.html',
  styleUrls: ['./custom-chip-colored.component.scss']
})
export class CustomChipColoredComponent {

  @Input() label:string = ''
  @Input() prefixImg:string = ''
  @Input() prefixAvatar:string = ''
  @Input() prefixAvatarWidth:string = ''
  @Input() prefixAvatarHeight:string = ''
  @Input() labelColor:string = ''
  @Input() labelClass:string = 'label-regular'
  @Input() color:string = ''
  @Input() showClose:boolean = false
  @Input() closeColor:string = ''

  @Output() handleClick = new EventEmitter<any>()

  click(){
    this.handleClick.emit()
  }
}
