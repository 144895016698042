import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { IResponse } from '../../models/iresponse';
import { Users } from '../../models/users';

@Injectable({
  providedIn: 'root'
})
export class HalaAccountsService {

  //API URLS
  API_URLGetUsersData = `${environment.apiUrl}/HalaAccounts/GetUsersData`;  
  API_URLGetUsersDataActive = `${environment.apiUrl}/HalaAccounts/GetUsersDataActive`;  
  API_URLLockOrUnLockUser = `${environment.apiUrl}/HalaAccounts/LockOrUnLockUser`;  
  API_URLUpdateUserRoles = `${environment.apiUrl}/HalaAccounts/UpdateUserRoles`;
  API_URLRegisterOrUpdate = `${environment.apiUrl}/HalaAccounts/RegisterOrUpdate`;
  API_URLChangePassword = `${environment.apiUrl}/HalaAccounts/ChangePassword`;
  API_URLChangeUsersPIN = `${environment.apiUrl}/HalaAccounts/ChangeUsersPIN`;
  

  constructor( private http: HttpClient ) {
  }

  RegisterOrUpdate(body:any):Observable<any> {
    const url = this.API_URLRegisterOrUpdate;    
    return this.http.post<any>(url,body)
  }

  GetUsersData(body:any):Observable<IResponse<Users>> {
    const url = this.API_URLGetUsersData;    
    return this.http.post<IResponse<Users>>(url,body)
  }

  GetUsersDataActive(body:any):Observable<any> {
      const url = this.API_URLGetUsersDataActive;    
      return this.http.post<any>(url,body)
  }

  LockOrUnLockUser(id:string,flag:any):Observable<any> {
    const url = this.API_URLLockOrUnLockUser;
    let param = {userId: id, lockFlag:flag};
    return this.http.get<any>(url,{params:param})
  }

  UpdateUserRoles(body:any): Observable<any> {
    const url = this.API_URLUpdateUserRoles;
    //Http request
    return this.http.post<any>(url,body)
  }

  ChangePassword(body:any): Observable<any> {
    const url = this.API_URLChangePassword;
    //Http request
    return this.http.post<any>(url,body)
  }

  ChangeUsersPIN(body:any): Observable<any> {
    const url = this.API_URLChangeUsersPIN;
    //Http request
    return this.http.post<any>(url,body)
  }
}
