import { Pipe, PipeTransform } from '@angular/core';
import { PermissionService } from '../services/permission.service';


@Pipe({
  name: 'rolesPermissionsCheck'
})
export class RolesPermissionsCheckPipe implements PipeTransform {

  constructor(private permissionService:PermissionService){}

  transform(item: any): boolean {
    return item ? (this.permissionService.checkRole(item.roles) && this.permissionService.checkPermission(item.permissions)) : false;
  }

}
