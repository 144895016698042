import { Component, Input } from '@angular/core';
import { LanguageService } from '../../services/language.service';

@Component({
  selector: 'app-language-selector',
  templateUrl: './language-selector.component.html',
  styleUrl: './language-selector.component.scss'
})
export class LanguageSelectorComponent {

  @Input() isLoginView:boolean = false
  cookieValue:any;
	flagvalue:any;
	countryName:any;
	valueset:any;
	listLang = [
		{ text: 'Arabic', flag: 'assets/images/flags/saudi.png', lang: 'ar' },
		{ text: 'English', flag: 'assets/images/flags/us.jpg', lang: 'en' },
		{ text: 'Hindi', flag: 'assets/images/flags/india.png', lang: 'hi' },
	];

  constructor(
		private languageService:LanguageService,
	) { }

  ngOnInit(): void {
		this.cookieValue = localStorage.getItem('lang');
		const val = this.listLang.filter(x => x.lang === this.cookieValue);
		this.countryName = val.map(element => element.text)[0];
		if (val.length === 0) {
		  if (this.flagvalue === undefined) { this.valueset = 'assets/images/flags/us.jpg'; }
		} else {
		  this.flagvalue = val.map(element => element.flag);
		}
	}

  setLanguage(text: string, lang: string, flag: string) {
		this.countryName = text;
		this.flagvalue = flag;
		this.cookieValue = lang;
		this.languageService.setLanguage(lang);
	  }
}
