import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-agents-dropdown',
  templateUrl: './agents-dropdown.component.html',
  styleUrl: './agents-dropdown.component.scss'
})
export class AgentsDropdownComponent {

  @Input() label:string = "Common.Agents"
  @Input() dataSource:any[] = []
  @Output() setSelected: EventEmitter<any> = new EventEmitter();

  form: FormGroup= this.fb.group({
    CUSTOMER_ID: new FormControl(""),
  });;

  constructor(
    private fb: FormBuilder,
  ) { }

  clear(){
    this.dataSource=[]
    this.form.reset()
  }

  onClick(){
    let id = (this.form.get('CUSTOMER_ID')?.value as any).CUSTOMER_ID
    this.setSelected.emit(id)
  }
}
