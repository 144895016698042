import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';

import { LanguageService } from '../../services/language.service';
import { ThPurchaseTargetService } from '../../services/thuraya/th-purchase-target.service';
import { Subject, takeUntil, tap } from 'rxjs';

@Component({
  selector: 'app-class-multiselect',
  templateUrl: './class-multiselect.component.html',
  styleUrls: ['./class-multiselect.component.scss']
})
export class ClassMultiselectComponent implements OnInit {

    _unsubscribe$: Subject<boolean> = new Subject()
    //customer multi select variables
    classDropDownList:any = []
    classDropDownListFiltered:any = []
    @Input() selectedValues: any = []
  
    @Output() addToList: EventEmitter<any> = new EventEmitter();
  
    constructor(
      private thPurchaseTargetService: ThPurchaseTargetService,
      public languageService:LanguageService,
      private ref: ChangeDetectorRef
    ) { }
  
    ngOnInit(): void {
    }

    // Called from parent on reset
    resetSelected(){
      this.selectedValues = []
    }
  
    getClassData(list:any) {
      //empty any selected before when selecting/deselecting services
      this.selectedValues = []
      this.thPurchaseTargetService.GetClassNameData(list)
      .pipe(
        tap(
          (result) => {
            if(result.resultList.length>0){
              result.resultList.map((i: any) => {
                this.classDropDownList.push({
                  _id: i.class_no,
                  name: i.class_name,
                  photo: ''
                })
              });
              this.classDropDownListFiltered = [...this.classDropDownList]
            }
          }
        ),
        takeUntil(this._unsubscribe$),
      )
      .subscribe();
    }
  
    onFilterChange(e:any){
      if(!e)
         this.classDropDownListFiltered=this.classDropDownList;
      else
      {
         e=e.toLowerCase();
         this.classDropDownListFiltered=this.classDropDownListFiltered.filter((x:any)=>x.class_no.toLowerCase()
            .indexOf(e)==0)
  
            //If no result show all else you get no data and no search bar
            if(this.classDropDownListFiltered.length==0){
              this.classDropDownListFiltered = this.classDropDownList
            }
      }
      
    }

    /*----------Adding tags to form arrays----------*/
  addToFiled(event: any) {
    this.selectedValues = event
    this.addToList.emit(this.selectedValues)
  }

  ngOnDestroy(): void {
    this._unsubscribe$.next(true)
    this._unsubscribe$.complete()
  }
  

}
