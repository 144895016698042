<button class="primary-button d-flex align-items-center justify-content-center" [ngClass]="hasDropdown? 'disbale-end-border' : ''"
  (click)="click()" [disabled]="isDisabled || isLoading">
  <div class="d-flex align-items-center gap-2">
    @if (lucidePrefixIcon) {
      <lucide-icon [name]="lucidePrefixIcon" [size]="lucidePrefixSize" [color]="lucidePrefixColor"></lucide-icon>
    }
    <img [src]="prefixIcon" alt="" *ngIf="prefixIcon != ''" />
    <span class="label-regular">{{ label | translate }}</span>
    <mat-spinner [diameter]="18" *ngIf="isLoading"></mat-spinner>
  </div>
</button>