import { Component, OnInit ,Input, Output, EventEmitter} from '@angular/core';

@Component({
  selector: 'app-excel-export',
  templateUrl: './excel-export.component.html',
  styleUrls: ['./excel-export.component.scss']
})
export class ExcelExportComponent implements OnInit {

  @Input() datasource:any;
  @Input() isExporting:boolean=false;

  @Output() exportExcel = new EventEmitter<any>();

  constructor() { }

  ngOnInit(): void {
  }

  export(boolean:boolean){
    if(this.datasource.length>0){
      this.exportExcel.emit(boolean);
    }
  }

}
