import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { IResponse } from '../../models/iresponse';
import { PurchaseTarget } from '../../models/purchaseTarget';

@Injectable({
  providedIn: 'root'
})
export class ThPurchaseTargetService {

  API_URLGetServicesData = `${environment.apiUrl}/ThdbPurchasesTarget/GetServicesData`;
  API_URLGetClassNameData = `${environment.apiUrl}/ThdbPurchasesTarget/GetClassNameData`;
  API_URLGetAllPurchasesTargetData = `${environment.apiUrl}/ThdbPurchasesTarget/GetAllPurchasesTargetData`;

  constructor(private http:HttpClient) { }

  GetServicesData(): Observable<any> {
    const url = this.API_URLGetServicesData;
    //Http request-
    return this.http.get<any>(url)
  }

  GetClassNameData(body:any): Observable<any> {
    const url = this.API_URLGetClassNameData;
    //Http request-
    return this.http.post<any>(url,body)
  }

  GetAllPurchasesTargetData(body:any): Observable<IResponse<PurchaseTarget>> {
    const url = this.API_URLGetAllPurchasesTargetData;
    //Http request-
    return this.http.post<IResponse<PurchaseTarget>>(url,body)
  }
}
