import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-print-report',
  templateUrl: './print-report.component.html',
  styleUrls: ['./print-report.component.scss','../excel-export/excel-export.component.scss']
})
export class PrintReportComponent implements OnInit {

  @Input() datasource:any;
  @Input() isPrinting:boolean=false;
  @Output() printReport = new EventEmitter<any>();

  constructor() { }

  ngOnInit(): void {
  }


  print(){
    if(this.datasource.length>0){
      this.printReport.emit();
    }
  }

}
