<div class="custom-toggle">
  <label class="switch"
    >{{ label ? (label | translate) : "" }}
    <input
      type="checkbox"
      checked
      [id]="id"
      [value]="value"
      (change)="click($event)"
      [checked]="isChecked"
      [disabled]="isDisabled"
    />
    <span class="slider round"></span>
  </label>
</div>
