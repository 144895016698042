import { Pipe, PipeTransform } from '@angular/core';
import { Router } from '@angular/router';

@Pipe({
  name: 'activeSecondaryLinks'
})
export class ActiveSecondaryLinksPipe implements PipeTransform {

  constructor(public router: Router,){}
  transform(item: any): boolean {
    return item &&  item.routeLinkSecondary ?  item.routeLinkSecondary!.some((i:any)=>this.router.url.includes(i)) : false ;
  }

}
