import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PrimaryButtonComponent } from './components/primary-button/primary-button.component';
import { AngularMaterialModule } from '../angular-material/angular-material.module';
import { CircleAvatarComponent } from './components/circle-avatar/circle-avatar.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ArrowUpFromLine, Banknote, Barcode, Calendar, ChartNoAxesGantt, ChartSpline, CircleAlert, CircleCheck, CircleX, CreditCard, 
  Dot, Eye, EyeOff, FileDigit, FileText, HandCoins, Hash, House, Import, Info, KeyRound, LogOut, LucideAngularModule, 
  MapPin, Menu, Monitor, NotebookPen, Pencil, Phone, Pin, Plus, Save, Search, Send, Settings, ShoppingCart, 
  Sigma, 
  SquareCheckBig, Tag, Text, ToggleRight, Trash, Undo2, User, Wallet, WalletCards, X,
} from 'lucide-angular';
import { NgxSpinnerModule } from 'ngx-spinner';
import { InfoCardComponent } from './components/info-card/info-card.component';
import { DateSelectorComponent } from './components/date-selector/date-selector.component';
import { PaginatorComponent } from './components/paginator/paginator.component';
import { ExcelExportComponent } from './components/excel-export/excel-export.component';
import { TextButtonComponent } from './components/text-button/text-button.component';
import { CustomCheckBoxComponent } from './components/custom/custom-check-box/custom-check-box.component';
import { RolesPermissionsCheckPipe } from './pipes/roles-permissions-check.pipe';
import { ServicesDropdownComponent } from './components/services-dropdown/services-dropdown.component';
import { LanguageSelectorComponent } from './components/language-selector/language-selector.component';
import { PrintReportComponent } from './components/print-report/print-report.component';
import { BillDetailsTableComponent } from './components/bill-details-table/bill-details-table.component';
import { QRCodeModule } from 'angularx-qrcode';
import { ProfileMenuComponent } from './components/profile-menu/profile-menu.component';
import { ClickOutsideDirective } from './directives/click-outside.directive';
import { MultiselectTagsComponent } from './components/multiselect-tags/multiselect-tags.component';
import { ServicesMultiselectComponent } from './components/services-multiselect/services-multiselect.component';
import { CustomChipColoredComponent } from './components/custom/custom-chip-colored/custom-chip-colored.component';
import { ClassMultiselectComponent } from './components/class-multiselect/class-multiselect.component';
import { ProvidersDropdownComponent } from './components/providers-dropdown/providers-dropdown.component';
import { UsersDropdownComponent } from './components/users-dropdown/users-dropdown.component';
import { NgxEchartsModule } from 'ngx-echarts';
import * as echarts from 'echarts';
import { ClassesDropdownComponent } from './components/classes-dropdown/classes-dropdown.component';
import { CompaniesDropdownComponent } from './components/companies-dropdown/companies-dropdown.component';
import { AgentsDropdownComponent } from './components/agents-dropdown/agents-dropdown.component';
import { AgentsAutocompleteDropdownComponent } from './components/agents-autocomplete-dropdown/agents-autocomplete-dropdown.component';
import { AllCustomersDropdownComponent } from './components/all-customers-dropdown/all-customers-dropdown.component';
import { ActiveSecondaryLinksPipe } from './pipes/active-secondary-links.pipe';
import { CustomToggleComponent } from './components/custom/custom-toggle/custom-toggle.component';
import { BanksDropdownComponent } from './components/banks-dropdown/banks-dropdown.component';
import { NgOtpInputModule } from 'ng-otp-input';

@NgModule({
  declarations: [
    PrimaryButtonComponent,
    CircleAvatarComponent,
    InfoCardComponent,
    DateSelectorComponent,
    PaginatorComponent,
    ExcelExportComponent,
    TextButtonComponent,
    CustomCheckBoxComponent,
    RolesPermissionsCheckPipe,
    ServicesDropdownComponent,
    LanguageSelectorComponent,
    PrintReportComponent,
    BillDetailsTableComponent,
    ProfileMenuComponent,
    ClickOutsideDirective,
    ServicesMultiselectComponent,
    MultiselectTagsComponent,
    CustomChipColoredComponent,
    ClassMultiselectComponent,
    ProvidersDropdownComponent,
    UsersDropdownComponent,
    ClassesDropdownComponent,
    CompaniesDropdownComponent,
    AgentsDropdownComponent,
    AgentsAutocompleteDropdownComponent,
    AllCustomersDropdownComponent,
    ActiveSecondaryLinksPipe,
    CustomToggleComponent,
    BanksDropdownComponent
  ],
  imports: [
    CommonModule,
    TranslateModule,
    FormsModule,
    ReactiveFormsModule,
    AngularMaterialModule,
    NgbModule,
    LucideAngularModule.pick({
      House,User,LogOut,Text,CircleX,
      Calendar,X,Dot,Pencil,Save,KeyRound,
      Pin,ToggleRight,Barcode,FileText,
      Send,Trash,Eye,EyeOff,CreditCard,
      ShoppingCart, Monitor, Info, SquareCheckBig, Undo2,
      Settings,Plus,NotebookPen,Banknote,ChartSpline, Tag,
      Phone, MapPin, FileDigit, Hash, Wallet, WalletCards,HandCoins, 
      ArrowUpFromLine, Import, Sigma, CircleAlert,CircleCheck,Menu,
      Search 
    }),
    NgxSpinnerModule,
    QRCodeModule,
    NgxEchartsModule.forRoot({ echarts }),
    NgOtpInputModule
  ],
  exports:[
    TranslateModule,
    FormsModule,
    ReactiveFormsModule,
    PrimaryButtonComponent,
    AngularMaterialModule,
    CircleAvatarComponent,
    NgbModule,
    LucideAngularModule,
    NgxSpinnerModule,
    InfoCardComponent,
    DateSelectorComponent,
    PaginatorComponent,
    ExcelExportComponent,
    TextButtonComponent,
    CustomCheckBoxComponent,
    RolesPermissionsCheckPipe,
    ServicesDropdownComponent,
    LanguageSelectorComponent,
    PrintReportComponent,
    BillDetailsTableComponent,
    QRCodeModule,
    ProfileMenuComponent,
    ClickOutsideDirective,
    ServicesMultiselectComponent,
    MultiselectTagsComponent,
    CustomChipColoredComponent,
    ClassMultiselectComponent,
    ProvidersDropdownComponent,
    UsersDropdownComponent,
    NgxEchartsModule,
    ClassesDropdownComponent,
    CompaniesDropdownComponent,
    AgentsDropdownComponent,
    AgentsAutocompleteDropdownComponent,
    AllCustomersDropdownComponent,
    ActiveSecondaryLinksPipe,
    CustomToggleComponent,
    BanksDropdownComponent,
    NgOtpInputModule
  ],
  providers:[ActiveSecondaryLinksPipe]
})
export class SharedModule { }
