import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { IResponse } from '../../models/iresponse';
import { Companies } from '../../models/companies';
import { Services } from '../../models/services';
import { Agents } from '../../models/agents';

@Injectable({
  providedIn: 'root'
})
export class ThGeneralSystemService {

  API_URLGetClassNameList = `${environment.apiUrl}/ThdbGeneralSystemActions/GetClassNameData`;
  API_URLGetServicesList = `${environment.apiUrl}/ThdbGeneralSystemActions/GetServicesData`;
  API_URLGetAllAgentsByCompany = `${environment.apiUrl}/ThdbGeneralSystemActions/GetAllAgentsByCompany`;
  API_URLGetAllDistributorsByAgent = `${environment.apiUrl}/ThdbGeneralSystemActions/GetAllDistributorsByAgent`;
  API_URLGetAllPosByDistributer = `${environment.apiUrl}/ThdbGeneralSystemActions/GetAllPosByDistributer`;
  API_URLGetAllCompanies = `${environment.apiUrl}/ThdbGeneralSystemActions/GetAllCompanies`;
  API_URLGetAllCustomerAccountData = `${environment.apiUrl}/ThdbGeneralSystemActions/GetAllCustomerAccountData`;
  API_URLGetAllAccountsByCompany = `${environment.apiUrl}/ThdbGeneralSystemActions/GetAllAccountsByCompany`;

  constructor(private http:HttpClient) { }

  GetClassNameData(body:any): Observable<any> {
    const url = this.API_URLGetClassNameList;
    //Http request-
    return this.http.post<any>(url,body)
  }

  GetServicesData(body:any): Observable<IResponse<Services>> {
    const url = this.API_URLGetServicesList;
    //Http request-
    return this.http.post<IResponse<Services>>(url,body)
  }

  GetAllCompanies(body:any): Observable<IResponse<Companies>> {
    const url = this.API_URLGetAllCompanies;
    //Http request-
    return this.http.post<IResponse<Companies>>(url,body)
  }

  GetAllAgentsByCompany(body:any): Observable<IResponse<Agents>> {
    const url = this.API_URLGetAllAgentsByCompany;
    //Http request-
    return this.http.post<IResponse<Agents>>(url,body)
  }

  GetAllDistributorsByAgent(body:any): Observable<IResponse<Agents>> {
    const url = this.API_URLGetAllDistributorsByAgent;
    //Http request-
    return this.http.post<IResponse<Agents>>(url,body)
  }

  GetAllPosByDistributer(body:any): Observable<any> {
    const url = this.API_URLGetAllPosByDistributer;
    //Http request-
    return this.http.post<any>(url,body)
  }

  GetAllCustomerAccountData(body:any): Observable<any> {
    const url = this.API_URLGetAllCustomerAccountData;
    //Http request-
    return this.http.post<any>(url,body)
  }

  GetAllAccountsByCompany(body:any): Observable<any> {
    const url = this.API_URLGetAllAccountsByCompany;
    //Http request-
    return this.http.post<any>(url,body)
  }
}
