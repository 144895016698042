import { Injectable } from '@angular/core';
import { Observable ,of} from "rxjs";
import { map,} from "rxjs/operators";
import { Router } from "@angular/router";
import { HttpClient, HttpParams } from "@angular/common/http";
// import { PresenceService } from "src/app/services/presence.service";
import { jwtDecode } from 'jwt-decode';

import { environment } from '../../../environments/environment';
import { PermissionService } from './permission.service';
import { User } from '../models/user';
import { v4 as uuidv4 } from 'uuid';
import { Login } from '../models/login';
import { IResponse } from '../models/iresponse';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

 //API URLS
 API_URLHalaAccounts = `${environment.apiUrl}/HalaAccounts`;
 API_URLLogin = `${environment.apiUrl}/HalaAccounts/Login`;
 API_URLRegister = `${environment.apiUrl}/HalaAccounts/RegisterOrUpdate`;
 
 user: User|any;
 
 //Variables
 authUser:any= null;

 constructor( 
     private router: Router,
     private http: HttpClient,
     private permissionService:PermissionService ) {
 }


 login(body:any):Observable<any> {
     const url = this.API_URLLogin;    
     return this.http.post<IResponse<Login>>(url,body)
 }

 register(body:any):Observable<any> {
     const url = this.API_URLRegister;    
     return this.http.post<any>(url,body)
 }

 
 VerifyThisOTP(email: string, code: string): Observable<any> {
    let params = new HttpParams().append('email', email).append('code', code)
    return this.http.get<any>(`${this.API_URLHalaAccounts}/VerifyOTP`,{params})
 }

 resetPassword(email: string) {
    //  return getFirebaseBackend().forgetPassword(email).then((response: any) => {
    //      const message = response.data;
    //      return message;
    //  });
 }

 logout() {
     localStorage.removeItem(environment.tokenName);
     localStorage.removeItem(environment.userDataName);
     this.authUser = null;
     this.router.navigate(["/auth/login"]);
 }

 public tokenExpired(token: string) {
     // const expiry = (JSON.parse(atob(token.split('.')[1]))).exp;
     // return (Math.floor((new Date).getTime() / 1000)) >= expiry;
     return false;
 }

 // Function to check if unique ID exists, if not, generate and store it
 public getUniqueId() {
    let uniqueId = localStorage.getItem(environment.deviceId);
    if (!uniqueId) {
      uniqueId = uuidv4();  // Generate a new UUID
      localStorage.setItem(environment.deviceId, uniqueId);
    }
  }

  // Function to clear unique ID (optional, for logout or cleanup)
  public clearUniqueId(): void {
    localStorage.removeItem(environment.deviceId);
  }

 //Get logged in user at init of app (app module)
 getUserByToken(): Observable<any> {
     this.getUniqueId();
     let userToken = localStorage.getItem(environment.tokenName);
     let userData = JSON.parse(localStorage.getItem(environment.userDataName)!);
     //Set user permission in the permission service (here case of refresh or access page without login as token not expired)
     this.permissionService.setPermissionsFromToken()
     if (!userToken || !userData) {
     return of(undefined);
     }else{
     //Check if token expired first
     if(this.tokenExpired(userToken)){
         return of(undefined);
     
     }else{
         return this.getDecodedToken(userToken).pipe(
         map((tokenData: any) => {
             if (tokenData) {
             this.authUser = {...tokenData,...userData};
             // console.log("Authed user",this.authUser)
             } else {
             // this.logout();
             }
             return this.authUser;
         }),
         )
     }
     }
 }

 getDecodedToken(token: string): Observable<any> {
     return of(jwtDecode(token));
 }

 //Used after Login /Register
 setAuthedUser(tokenData:any,userData:any){
     this.authUser =  {...tokenData, ...userData};
     console.log("AuthUser",this.authUser)
 }

}
