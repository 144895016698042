import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { IResponse } from '../../models/iresponse';
import { Providers } from '../../models/providers';


@Injectable({
  providedIn: 'root'
})
export class HalaProvidersService {

  API_URLGetAllProvidersData = `${environment.apiUrl}/HalaProviders/GetAllProvidersData`;
  API_URLGetAllProvidersActiveData = `${environment.apiUrl}/HalaProviders/GetAllProvidersActiveData`;
  API_URLInsertProvider = `${environment.apiUrl}/HalaProviders/InsertProvider`;
  API_URLUpdateProvider = `${environment.apiUrl}/HalaProviders/UpdateProvider`;
  API_URLDeleteProvider = `${environment.apiUrl}/HalaProviders/DeleteProvider`;
  
  constructor(private http:HttpClient) { }

  GetAllProvidersData(body:any): Observable<IResponse<Providers>> {
    const url = this.API_URLGetAllProvidersData;
    //Http request
    return this.http.post<IResponse<Providers>>(url,body)
  }

  GetAllProvidersActiveData(body:any): Observable<any> {
    const url = this.API_URLGetAllProvidersActiveData;
    //Http request-
    return this.http.post<any>(url,body)
  }

  InsertProvider(body:any): Observable<any> {
    const url = this.API_URLInsertProvider;
    //Http request
    return this.http.post<any>(url,body)
  }

  UpdateProvider(body:any): Observable<any> {
    const url = this.API_URLUpdateProvider;
    //Http request
    return this.http.post<any>(url,body)
  }

  DeleteProvider(providerId:string): Observable<any> {
    const url = this.API_URLDeleteProvider;
    //Http request
    let param = {id: providerId};
    return this.http.get<any>(url,{params:param})

  }
}
