import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-text-button',
  templateUrl: './text-button.component.html',
  styleUrls: ['./text-button.component.scss']
})
export class TextButtonComponent {

  @Input() label:string = ""
  @Input() icon:string = ""
  @Input() textColorClass:string = "blue-600"
  @Input() fontClass:string = "label-regular"
  @Input() showIcon:boolean = true
  @Input() lucidePrefixIcon:string = ''
  @Input() lucidePrefixSize:string = '18'
  @Input() lucidePrefixColor:string = '#000000'
  @Input() isDisabled: boolean = false;
 
  @Output() handleClick = new EventEmitter<any>()

  classesList:any[]=[]

  ngOnInit() {
  this.classesList.push(this.textColorClass);
  this.classesList.push(this.fontClass);

  }

  click(){
    this.handleClick.emit()
  }
}
