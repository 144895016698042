<form action="" [formGroup]="form">
    <mat-form-field appearance="outline" >
        <mat-label>{{ label | translate }}</mat-label>
        <input
            type="text"
            aria-label="auto complete"
            matInput
            formControlName="company_id"
            [matAutocomplete]="autoServices"
            (input)="onType(autocompleteServices.value)"
            #autocompleteServices
        />
        <mat-autocomplete
            #autoServices="matAutocomplete"
            [displayWith]="displayFn.bind(this)"
        >
            <ng-container *ngFor="let item of dataSource">
              <mat-option [value]="item" (click)="changeSelectedCompany($event)">
                [{{ item.company_no}}] - {{item.company_name}}
              </mat-option>
              <mat-divider class="chart-divider"></mat-divider>
            </ng-container>
        </mat-autocomplete>
    
        <mat-error *ngIf="form.get('company_id')?.errors?.['required']">
            {{ 'Common.RequiredField' | translate }}
        </mat-error>
        <mat-error *ngIf=" this.form.get('company_id')?.errors?.['autoCompleteRequired']">
            {{ 'Common.ChooseFromListReq' | translate }}
        </mat-error>
    </mat-form-field>
</form>