import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-circle-avatar',
  templateUrl: './circle-avatar.component.html',
  styleUrls: ['./circle-avatar.component.scss']
})
export class CircleAvatarComponent {

  @Input() data:any
  @Input() img:any
  @Input() width = '3.11525rem'
  @Input() height = '3.11525rem'
  @Input() radius = '50%'
  @Input() dataClass = 'label-regular'
  @Input() backgroundColor = '#e4e4e7'
  
}
