import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Subject, takeUntil, tap } from 'rxjs';
import { autoCompleteRequired } from '../../validators/autocompleteRequired';
import { ThGeneralSystemService } from '../../services/thuraya/th-general-system.service';
import { IResponse } from '../../models/iresponse';
import { Agents } from '../../models/agents';

@Component({
  selector: 'app-agents-autocomplete-dropdown',
  templateUrl: './agents-autocomplete-dropdown.component.html',
  styleUrl: './agents-autocomplete-dropdown.component.scss'
})
export class AgentsAutocompleteDropdownComponent {


  _unsubscribe$: Subject<boolean> = new Subject()
  dataSource:Agents[] = []
  timeOut:any=0
  
  form = this.fb.group({
    CUSTOMER_ID: ['', [Validators.required,autoCompleteRequired()]],
  }) 
  @Input() label:string = "Common.Agents"
  @Output() setSelected: EventEmitter<any> = new EventEmitter();

  constructor(
    private fb: FormBuilder,
    private thGeneralSystemService:ThGeneralSystemService,
  ) { }

  ngOnInit(): void {
    this.getAgents()
  }

  /*----------------------Functions Called from parent---------------------- */
  emptyData(data:any){
    this.dataSource = []
    this.getAgents();
  }

  setValue(data:any){
    let index = this.dataSource.findIndex((i:any)=>i.CUSTOMER_ID==data)
    if(index>-1){
      this.form.get('CUSTOMER_ID')?.setValue((this.dataSource[index] as any))
    }
  }
  reset(){
    this.form.reset()
  }
  

  /*----------------------Getting Companies for dropdown select---------------------- */

  onType(value: string) {
    if (value.length == 0 || value.length > 1) {
      clearTimeout(this.timeOut)
      this.timeOut = setTimeout(() => {
        this.dataSource=[]
        this.getAgents(value)
      }, 1000)
    }
  }

  getAgents(searchValue:string="") {
    let body={
      pageNumber: 1,
      pageSize: 100,
      searchStrings: searchValue,
      isPaginated: true,
      company_No: "",
      accountId:"", 
    }
    this.thGeneralSystemService.GetAllAgentsByCompany(body)
    .pipe(
      tap(
        (result:IResponse<Agents>) => {
          if(result.IsSuccess && result.Data){
            this.dataSource = result.Data;
          }
        }
      ),
      takeUntil(this._unsubscribe$),
    )
    .subscribe();
  }

  /*----------------------when Choosing Company---------------------- */
  changeSelected(selectedValue:any){ 
    // console.log("selected",selectedValue)
    if(selectedValue){
      let id = (this.form.get('CUSTOMER_ID')?.value as any).CUSTOMER_ID
      this.setSelected.emit(id)
    }else{
      this.setSelected.emit("")
    }
  }

  displayFn(item:any) {
    if (!item) return '';
    let index = this.dataSource.findIndex(e => e.company_no == item.company_no);
    return `[${this.dataSource[index].CUSTOMER_ID}] - ${this.dataSource[index].CUSTOMER_NAME}`;
  }


  ngOnDestroy(): void {
    this._unsubscribe$.next(true)
    this._unsubscribe$.complete()
  }

}
