import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Component, EventEmitter, Input, Output, inject } from '@angular/core';
import { LiveAnnouncer } from '@angular/cdk/a11y';

export interface Fruit {
  name: string;
}

@Component({
  selector: 'app-multiselect-tags',
  templateUrl: './multiselect-tags.component.html',
  styleUrls: ['./multiselect-tags.component.scss']
})
export class MultiselectTagsComponent {
  @Input() multiple: boolean = true
  @Input() autoCompleteList: any[] = []
  @Input() selectedItems: any[] = []
  @Input() filedName: string = ''
  @Input() isTagField: boolean = false
  @Input() tagBgColor: string = '#e2e8f0'
  @Input() tagCloseColor: string = '##303030'
  @Input() showTagClose: boolean = false
  @Input() isDisabled: boolean = false
  @Output() onSelect: EventEmitter<any> = new EventEmitter();
  @Output() onBlur: EventEmitter<any> = new EventEmitter();
  @Output() onType: EventEmitter<any> = new EventEmitter();
  selectedTags: any[] = []
  // addOnBlur = true;
  readonly separatorKeysCodes = [ENTER, COMMA] as const;
  // fruits: Fruit[] = [{name: 'Lemon'}, {name: 'Lime'}, {name: 'Apple'}];

  announcer = inject(LiveAnnouncer);

  ngOnInit() {
    if (this.selectedItems.length > 0) {
      this.selectedTags = [...this.selectedItems];
    }
  }

  ngOnChanges() {

    this.selectedTags = this.selectedItems


  }

  remove(index: any): void {
    this.selectedTags.splice(index, 1);
    this.onSelect.emit(this.selectedTags)
  }

  clearAll() {

    this.onSelect.emit(this.selectedTags)
  }

  typeHandle(event: any) {
    this.onType.emit(event)
  }
  onblurInput() {
    this.onBlur.emit(true)
  }

  select(event: any) {
    if (this.multiple) {
      let found = this.isTagField ? this.selectedTags.findIndex(i => i.name == event.name && i.color == event.color) : this.selectedTags.findIndex(i => i._id == event._id)
      if (found < 0) {
        this.selectedTags.push(event)
        this.onSelect.emit(this.selectedTags)
      }else{
        this.remove(found)
      }
    } else {
      this.selectedTags = []
      this.selectedTags.push(event)
      this.onSelect.emit(this.selectedTags)
    }

  }
}
