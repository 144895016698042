import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { HalaProvidersService } from '../../services/hala/hala-providers.service';
import { FormBuilder, Validators } from '@angular/forms';
import { autoCompleteRequired } from '../../validators/autocompleteRequired';
import { Subject, takeUntil, tap } from 'rxjs';

@Component({
  selector: 'app-providers-dropdown',
  templateUrl: './providers-dropdown.component.html',
  styleUrls: ['./providers-dropdown.component.scss']
})
export class ProvidersDropdownComponent implements OnInit {

  _unsubscribe$: Subject<boolean> = new Subject()

  @Output() updateParent: EventEmitter<any> = new EventEmitter();
  @Output() setProviderName: EventEmitter<any> = new EventEmitter();
  
  dataSource:any[] = []
  timeOut:any=0
  form = this.fb.group({
    provider_id: ['', [Validators.required,autoCompleteRequired()]],
  })
  constructor(
    public halaProvidersService: HalaProvidersService,
    private fb: FormBuilder,
  ) { }

  ngOnInit(): void {
    this.getProviders()
  }

  /*----------------------Functions Called from parent---------------------- */
  setValue(data:any){
    let index = this.dataSource.findIndex((i:any)=>i.id==data)
    if(index>-1){
      this.form.get('provider_id')?.setValue(this.dataSource[index])
    }
  }

  reset(){
    this.form.reset()
  }

  getProviderFromList(providerId:any){
    return this.dataSource.filter(x => x.id == providerId)
  }

  /*----------------------Getting providers for dropdown select---------------------- */


  onType(value: string) {
    if (value.length == 0 || value.length > 1) {
      clearTimeout(this.timeOut)
      this.timeOut = setTimeout(() => {
        this.dataSource=[]
        this.getProviders(value)
      }, 1000)
    }
  }

  getProviders(searchValue:string='') {
    let body = {
      pageNumber:1,
      pageSize: 100,
      isPaginated: false,
      searchStrings:searchValue
    }
    this.halaProvidersService.GetAllProvidersActiveData(body)
    .pipe(
      tap(
        (result) => {
          if(result.IsSuccess && result.Data){
            this.dataSource = result.Data;
          }
        }
      ),
      takeUntil(this._unsubscribe$)
    ).subscribe();
  }
  
  /*----------------------when Choosing Provider---------------------- */
  changeSelectedProvider(selectedValue:any){ 
    // console.log("selected",selectedValue)
    if(selectedValue){
      let id= (this.form.get('provider_id')?.value as any).id
      let name= (this.form.get('provider_id')?.value as any).ProviderName
      this.updateParent.emit(id);
      this.setProviderName.emit(name)   
    }else{
      this.updateParent.emit("");   
      this.setProviderName.emit("")   
    }
  }

  displayFn(item:any) {
    if (!item) return '';
    let index = this.dataSource.findIndex(e => e.id == item.id);
    return `[${this.dataSource[index].id}] - ${this.dataSource[index].ProviderName}`;
  }

  ngOnDestroy(): void {
    this._unsubscribe$.next(true)
    this._unsubscribe$.complete()
  }

}
