<div class="profile-avatar d-flex align-items-center gap-1" href="javascript:void(0);" role="button" (appClickOutside)="isMenuOpen=false">
    <app-circle-avatar [img]="'assets/images/avatar-bg.png'" [width]="'2rem'"  (click)="toggleMenu()" [height]="'2rem'"[radius]="'5rem'"></app-circle-avatar>
    <div class="custom-dropdown-menu" [@menuState]="isMenuOpen ? 'open' : 'closed'">
        <div class="d-flex flex-column px-3 pt-3">
            <span class="text-white fw-bold">{{authService.authUser ? authService.authUser.FullName : '--'}}</span>
            <small class="text-xs text-white-70">{{ authService.authUser ? authService.authUser.role : '--'}}</small>
        </div>
        <hr>
        <div class="px-3 pb-3">
            <a (click)="goToProfile();isMenuOpen=false" class="dropdown-item ai-icon d-flex align-items-center gap-2">
                <lucide-icon size="18" name="user"></lucide-icon>
                <span class=""> {{ 'HEADER.Profile' | translate}} </span>
            </a>
            
            <a class="dropdown-item ai-icon d-flex align-items-center gap-2" (click)="authService.logout()" style="cursor: pointer;">
                <lucide-icon size="18" name="toggle-right"></lucide-icon>
                <span class="">{{ 'HEADER.Logout' | translate}} </span>
            </a>
        </div>
    </div>
</div>